@use 'index';

.container {
  margin-top: 10%;
  margin-left: 15%;
  margin-right: 15%;
}

#root {
  color: index.$lightColor;
}