.Projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
}

.Projects-Header {
  font-size: 5rem;
}

.Projects-Text {
  font-size: 2rem;
}

.Projects-Blurb {
  text-align: center;
}

.Projects-Link {
  color: yellow;
  cursor: pointer;
  margin: 0;
  padding: 0;
  font-weight: 500;
}

.Projects-Link:hover {
  color: white;
}

.Projects-Content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}