@use 'index';

.Drawer {
  @include index.base-container;
  @include index.slide-from-right;
  position: absolute;
  top: 0;
  left: 0;
}

.Drawer-Closed {
  @include index.slide-from-left;
}

.Drawer-Link {
  @include index.link($color: index.$darkColor, $fontSize: 2.5rem);
  display: flex;
  width: 100%;
  margin: 1rem 0;
  text-align: center;
  padding-left: 1rem;
}

.Drawer-Links {
  @include index.column;
  justify-content: center;
}

.Drawer-Content {
  @include index.base-container;
  @include index.column;
  @include index.theme($themePrimary: index.$lightColor, $themeSecondary: index.$lightColor);
  top: 0;
  right: 50%;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: inherit;
  z-index: 1;
}

.Drawer-Page {
  @include index.base-container($width: 100%);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
}