.Footer {
  clear:both;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  height: 5rem; 
  margin-top: 20rem;
  padding: 1rem;
  color: rgb(21,21,21);
  background-color: white;
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.Footer-Link {
  cursor: pointer;
  color: rgb(53, 53, 53);
}

.Footer-Link:hover {
  color: rgb(12,12,12);
}