$darkColor: rgb(21,21,21);
$lightColor: white;
$altColor: yellow;

/* Mixins */

// Animation mixins

@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @-o-keyframes #{$name} {
    @content;
  }
}

@mixin fade-in($duration: 2s) {
  @include keyframes($name: fadein) {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  -webkit-animation: fadein $duration; /* Safari 4+ */
  -moz-animation:    fadein $duration; /* Fx 5+ */
  -o-animation:      fadein $duration; /* Opera 12+ */
  animation:         fadein $duration; /* IE 10+, Fx 29+ */
}

@mixin fade-out($duration: 2s) {
  @include keyframes($name: fadeout) {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  -webkit-animation: fadeout $duration; /* Safari 4+ */
  -moz-animation:    fadeout $duration; /* Fx 5+ */
  -o-animation:      fadeout $duration; /* Opera 12+ */
  animation:         fadeout $duration; /* IE 10+, Fx 29+ */
}

@mixin slide-from-left($duration: 500ms) {
  @include keyframes($name: slideFromLeft) {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  -webkit-animation: slideFromLeft $duration; /* Safari 4+ */
  -moz-animation:    slideFromLeft $duration; /* Fx 5+ */
  -o-animation:      slideFromLeft $duration; /* Opera 12+ */
  animation:         slideFromLeft $duration; /* IE 10+, Fx 29+ */
}

@mixin slide-from-right($duration: 500ms) {
  @include keyframes($name: slideFromRight) {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  -webkit-animation: slideFromRight $duration; /* Safari 4+ */
  -moz-animation:    slideFromRight $duration; /* Fx 5+ */
  -o-animation:      slideFromRight $duration; /* Opera 12+ */
  animation:         slideFromRight $duration; /* IE 10+, Fx 29+ */
}

// Theme mixins

@mixin theme($themePrimary: $primaryColor, $themeSecondary: $secondaryColor) {
  background: $themePrimary;
  color: $themeSecondary;
}

// Structural mixins

@mixin row($height: 100%, $width: 100%) {
  display: flex;
  flex-direction: row;
  height: $height;
  width: $width;
  flex-wrap: wrap;
}

@mixin column($height: 100%, $width: 100%) {
  display: flex;
  flex-direction: column;
  height: $height;
  width: $width;
  flex: 1 1 0;
}

@mixin base-container($height: 100vh, $width: 100vw) {
  height: $height;
  width: $width;
  position: relative;
  padding: 0;
  margin: 0;
}

@mixin link($color: $altColor, $fontSize: 2rem) {
  color: $color;
  font-size: $fontSize;
  text-decoration: none;
  font-weight: 500;
}

@mixin rounded($width: 15rem, $height: 5rem, $borderRadius: 5rem) {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  font-size: 1.5rem;
  font-weight: 500;
  height: $height;
  width: $width;
  border-radius: $borderRadius;
  cursor: pointer;
}

@mixin button($width: 15rem, $height: 5rem, $borderRadius: 5rem, $primaryColor: $lightColor, $hoverColor: $altColor, $textColor: $darkColor) {
  @include rounded($width: $width, $height: $height, $borderRadius: $borderRadius);
  background-color: $primaryColor;
  color: $textColor;
  background: linear-gradient(to right, $hoverColor 50%, $primaryColor 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all .5s ease-out;
  &:hover {
    border: 2px solid $darkColor;
    background-position: left bottom;
    color: $darkColor;
  }
  &:active {
    border: 2px solid $darkColor;
    background-position: left bottom;
    color: $darkColor;
  }
}

@mixin pill($width: 15rem, $height: 5rem, $borderRadius: 5rem, $primaryColor: $lightColor, $textColor: $darkColor) {
  @include rounded($width: $width, $height: $height, $borderRadius: $borderRadius);
  background-color: $primaryColor;
  color: $textColor;
  
  &:hover {
  transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  }
}

/* Tags */

html {
  @include base-container;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 10px;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  @include base-container($height: inherit, $width: inherit);
  background-color: $darkColor;
}
