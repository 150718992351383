@use 'index';

.Skills {
  @include index.row;
  width: inherit;
  height: fit-content;
  justify-content: space-evenly;
}

.Skills-Header {
  font-size: 5rem;
  color: index.$lightColor;
  text-align: center;
}

.Skill-Pill {
  @include index.pill($width: 20rem, $height: 4rem);
  box-shadow: 0 0 3px index.$altColor;
  margin: 1rem 2rem;
}