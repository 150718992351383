.Project-Image {
  width: 40%;
  box-shadow: 0 0 5px rgb(63,66,85);
  cursor: pointer;
}

.Project-Image img {
  width: 100%;
  height: 100%;
}

.Project-Image img.active {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  height: 75%;
  z-index: 100;
  box-shadow: 0 0 10px rgb(63,66,85);
}

@media only screen and (max-width: 850px) {
  .Project-Image {
    margin-top: 1rem;
    width: 100%;
  }

  .Project-Image img.active {
    width: 90%;
    height: 90%;
  }
}