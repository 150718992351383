.About {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.About-BG {
  background-image: url('../img/abt-bg.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  width: inherit;
  height: 100vh;
  text-align: center;
}

.About-Photo {
  width: 25rem;
  margin-right: 5rem;
  align-self: center;
}

.About-Content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 5rem;
}

.About-Header {
  font-size: 5rem;
  padding-right: 5px;
  width: fit-content;
}

.About-Header-Span {
  color: yellow;
}

.has-border {
  border-right: 5px solid white;
}

.About-Body {
  font-size: 2rem;
}

.About-Footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.About-Contact {
  border: none;
  outline: none;
  border-radius: 4rem;
  text-align: center;
  display: flex;
  align-items: center;
  width: fit-content;
  height: fit-content;
  padding: 1.25rem 2rem;
  border: 2px solid white;
  color: rgb(21,21,21);
  background: linear-gradient(to right, yellow 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all .5s ease-out;
  font-weight: 600;
  font-size: 1.75rem;
  cursor: pointer;
}

.About-Contact:hover {
  border: 2px solid rgb(21,21,21);
  background-position: left bottom;
  color: rgb(21,21,21);
}

.About-Contact:active {
  background-color: rgb(21,21,21);
  color: rgb(21,21,21);
}

.About-Social {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  font-weight: 600;
  flex-wrap: wrap;
}

.About-Link {
  color: yellow;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.About-Link:hover {
  color: white;
}

.About-Seperator {
  margin: 0 1rem;
}

@media only screen and (max-width: 1020px) {
  .About {
    flex-direction: column;
    justify-content: center;
  }

  .About-Content {
    margin: 0;
  }

  .About-Photo {
    align-self: center;
  }
}

@media only screen and (max-width: 600px) {
  .has-border {
    border-right: none;
  }

  .About-Footer {
    flex-direction: column;
    justify-content: center;
  }

  .About-Social {
    margin-top: 1rem;
  }

}

@media only screen and (max-width: 800px) {
  .About-Link {
    width: 100%;
    text-align: center;
  }

  .About-Seperator {
    display: none;
  }
}