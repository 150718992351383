@use 'index';

.Project {
  @include index.base-container($width: 100%, $height: 100%);
  @include index.row;
  align-items: center;
  justify-content: space-between;
  flex: 0.5;
}

.Project-Info {
  @include index.column($width: 40%);
  align-items: flex-start;
  flex: unset;
}

.Project-Header {
  font-size: 3rem;
  text-align: center;
}

.Project-Description {
  font-size: 2rem;
}

@media only screen and (max-width: 850px) {
  .Project {
    flex-direction: column;
  }

  .Project-Info {
    width: 100%;
  }
}